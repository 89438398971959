@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'JosefinsSans', 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
}

code {
  font-family: 'Poppins';
}
